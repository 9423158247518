<template>
  <div class="course-page" :style="'background-image: url(' + courseInfo.background + ')'">
    <div class="page-head">
      <div class="left">
        <div class="menu-icon" @click="showModelFun">
          <el-icon class="el-icon-menu" v-if="!modelShow"></el-icon>
          <el-icon class="el-icon-close" v-else></el-icon>
        </div>
      </div>
      <div class="right">
        <div class="back" @click="back">
          <el-icon class="el-icon-back"></el-icon>
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="content-swiper">
        <swiper class="swiper-container" ref="mySwiper" :options="swiperOptions">
          <swiper-slide class="swiper-slide" v-for="item in courseInfo.content" :key="item">
            <img :src="item" v-if="getType(item) === '.jpg' || getType(item) === '.png' || getType(item) === '.gif' || getType(item) === '.JPG' || getType(item) === '.PNG' || getType(item) === '.GIF'"/>
            <video :src="item" controls="controls" controlslist="nodownload" v-else></video>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev">
          <el-icon class="el-icon-arrow-left"></el-icon>
        </div>
        <div class="swiper-button-next">
          <el-icon class="el-icon-arrow-right"></el-icon>
        </div>
      </div>
      <div class="preview-swiper">
        <div class="preview-icon" @click="togglePreview()">
          <el-icon class="el-icon-d-caret"></el-icon>
        </div>
        <transition name="fade">
          <swiper class="preview-swiper-container" ref="previewSwiper" :options="previewSwiperOptions" v-show="showPreview">
            <swiper-slide class="preview-swiper-slide" v-for="(item, index) in courseInfo.content" :key="item" @click.native="setThumbsSwiper(index)">
              <img :src="item" v-if="getType(item) === '.jpg' || getType(item) === '.png' || getType(item) === '.gif' || getType(item) === '.JPG' || getType(item) === '.PNG' || getType(item) === '.GIF'"/>
              <video :src="item" controls="controls" controlslist="nodownload" v-else></video>
            </swiper-slide>
          </swiper>
        </transition>
        <div class="swiper2-button-prev">
          <el-icon class="el-icon-arrow-left"></el-icon>
        </div>
        <div class="swiper2-button-next">
          <el-icon class="el-icon-arrow-right"></el-icon>
        </div>
      </div>
      <!-- 弹窗 -->
      <div class="model-panel" v-show="modelShow">
        <div class="left-menu">
          <div :class="[modelActiveMenu === item.id ? 'active' : '', 'menu-item']" v-for="item in courseInfo.subject" :key="item.id" @click="showModelContent(item)">
            <el-icon class="el-icon-loading"></el-icon>
            {{item.title}}
          </div>
        </div>
        <div class="right-content">
          <swiper class="model-swiper-container" ref="modelSwiper" :options="modelSwiperOptions" v-if="modelShowData.type === 1">
            <swiper-slide class="model-swiper-slide" v-for="item in modelShowData.img_content" :key="item">
              <img :src="item" v-if="getType(item) === '.jpg' || getType(item) === '.png' || getType(item) === '.gif' || getType(item) === '.JPG' || getType(item) === '.PNG' || getType(item) === '.GIF'"/>
              <video :src="item" controls="controls" controlslist="nodownload" v-else></video>
            </swiper-slide>
          </swiper>
          <div class="model-swiper-prev" v-if="modelShowData.type === 1">
            <el-icon class="el-icon-arrow-left"></el-icon>
          </div>
          <div class="model-swiper-next" v-if="modelShowData.type === 1">
            <el-icon class="el-icon-arrow-right"></el-icon>
          </div>
          <video class="right-content-video" :src="modelShowData.video_content" controls="controls" controlslist="nodownload" v-if="modelShowData.type === 2"></video>
        </div>
      </div>
    </div>
    <!-- 全屏文字水印 -->
    <div ref="watermark" class="watermark" :style="'left:' + watermarkInfo.x + 'px; top:' +  watermarkInfo.y + 'px;'">
      {{this.$store.state.schoolInfo.watermark}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Course',
  data () {
    return {
      courseInfo: {
        m_id: '',
        tid: '',
        title: '',
        thumbnail: '',
        background: '',
        content: '',
        steam: '',
        culture: '',
        thinking: '',
        step: '',
        subject: []
      },
      swiperOptions: {
        direction: 'horizontal',
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      previewSwiperOptions: {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
        navigation: {
          nextEl: '.swiper2-button-next',
          prevEl: '.swiper2-button-prev'
        }
      },
      showPreview: false,
      modelShow: false,
      modelActiveMenu: 0,
      modelShowData: {
        title: '',
        type: 0,
        img_content: [],
        video_content: ''
      },
      modelSwiperOptions: {
        direction: 'horizontal',
        navigation: {
          nextEl: '.model-swiper-next',
          prevEl: '.model-swiper-prev'
        }
      },
      watermarkInfo: {
        x: 0,
        y: 0,
        xs: 10,
        ys: 10
      },
      // 进入页面的时间戳
      viewInfo: {
        course_id: this.$route.params.course_id,
        enter_time: 0
      }
    }
  },
  created () {
    this.getCourseInfo()
  },
  methods: {
    async getCourseInfo () {
      const { data: res } = await this.$http.get('/course', { params: { m_id: this.$route.params.course_id } })
      if (res.code === 200) {
        this.courseInfo.m_id = res.data.m_id
        this.courseInfo.tid = res.data.tid
        this.courseInfo.title = res.data.title
        this.courseInfo.thumbnail = res.data.thumbnail
        this.courseInfo.background = res.data.background
        this.courseInfo.content = res.data.content
        this.courseInfo.steam = res.data.steam
        this.courseInfo.culture = res.data.culture
        this.courseInfo.thinking = res.data.thinking
        this.courseInfo.step = res.data.step
        this.courseInfo.subject = res.data.subject
      } else {
        this.$message.error(res.msg)
      }
    },
    // 判断url字符串的文件后缀
    getType (file) {
      const filename = file
      const index1 = filename.lastIndexOf('.')
      const index2 = filename.length
      const type = filename.substring(index1, index2)
      return type
    },
    back () {
      this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + this.$route.params.special_id + '/classify/' + this.$route.params.classify_id)
    },
    showModelContent (item) {
      this.modelActiveMenu = item.id
      this.modelShowData.title = item.title
      this.modelShowData.type = item.type
      this.modelShowData.img_content = item.img_content
      this.modelShowData.video_content = item.video_content
    },
    watermarkInit () {
      const watermarkDom = this.$refs.watermark
      if (this.$store.state.schoolInfo.watermark) {
        this.watermarkInfo.x += this.watermarkInfo.xs
        this.watermarkInfo.y += this.watermarkInfo.ys
        if (this.watermarkInfo.x > document.body.clientWidth - watermarkDom.offsetWidth - 20 || this.watermarkInfo.x < 0) {
          this.watermarkInfo.xs = -1 * this.watermarkInfo.xs // 速度取反
        }

        if (this.watermarkInfo.y > document.body.clientHeight - watermarkDom.offsetHeight - 20 || this.watermarkInfo.y < 0) {
          this.watermarkInfo.ys = -1 * this.watermarkInfo.ys
        }
      }
    },
    // 发送请求到后端更新课程总时间
    async updateViewTime () {
      await this.$http.post('/updata-view-time', this.viewInfo)
    },
    // 显示和隐藏弹窗
    showModelFun () {
      if (this.modelShow) {
        this.modelShow = false
        document.querySelector('.page-head').classList.remove('show-head')
      } else {
        this.modelShow = true
        document.querySelector('.page-head').classList.add('show-head')
      }
    },
    setThumbsSwiper (index) {
      console.log(index)
      this.swiper.slideTo(index, 1000, false)
    },
    // 切换缩略图的显示和隐藏
    togglePreview () {
      this.showPreview = !this.showPreview
    }
  },
  mounted () {
    if (this.$store.state.schoolInfo.watermark) {
      const _this = this
      this.timer = setInterval(() => {
        // 水印
        _this.watermarkInit()
      }, 100)
    }
    this.viewInfo.enter_time = Date.parse(new Date()) / 1000
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.updateViewTime()
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  }
}
</script>

<style scoped>
.course-page{
  width: 100%;
  height: 100vh;
  background-size: cover;
}
.page-head{
  height: 80px;
  background-color: #FFFFFF;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  opacity: 0;
  transition: opacity .2s ease-in
}
.page-head:hover{
  opacity: 1;
}
.page-head.show-head{
  opacity: 1;
}
.page-head .left{}
.page-head .left .menu-icon{
  background-color: #FBAE17;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #FFFFFF;
  cursor: pointer;
}
.page-head .right{}
.page-head .right .back{
  background-color: #FBAE17;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  color: #FFFFFF;
  cursor: pointer;
}
.page-content{
  width: 100%;
  height: 100%;
  position: relative;
}
.content-swiper{
  width: 100%;
  height: 100%;
}
.swiper-container{
  height: 100%;
  width: 100%;
}
.swiper-slide{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.swiper-slide img{
  height: 100%;
  width: 100%;
}
.swiper-slide video{
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.8);
  max-height: 100%;
}
.swiper-button-prev:after{
  content: none;
}
.swiper-button-prev{
  width: 40%;
  height: 60%;
  left: 0;
  cursor: pointer;
  top: 20%;
  margin-top: 0;
  opacity: 0;
}
.swiper-button-next:after{
  content: none;
}
.swiper-button-next{
  width: 40%;
  height: 60%;
  right: 0;
  cursor: pointer;
  color: #FFFFFF;
  top: 20%;
  margin-top: 0;
  opacity: 0;
}
.model-panel{
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  z-index: 200;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: nowrap;
  pointer-events: auto;
}
.model-panel .left-menu{
  width: 200px;
  background-color: rgba(0,0,0,0.9);
  height: 100%;
}
.model-panel .left-menu .menu-item{
  text-align: center;
  padding: 16px 0;
  color: #FFFFFF;
  font-size: 14px;
  cursor: pointer;
}
.model-panel .left-menu .active{
  background-color: #FBAE17;
  color: rgba(0,0,0,0.8);
}
.model-panel .right-content{
  width: calc(100% - 200px);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: rgba(0,0,0,.8);
  padding: 40px;
}
.model-panel .right-content .swiper-container{
  width: 100%;
  height: 100%;
  border: 10px solid #FBAE17;
  border-radius: 10px;
}
.right-content .model-swiper-container{
  width: calc(100% - 200px);
}
.right-content .model-swiper-container .model-swiper-slide{
  width: 100%;
  height: 100%;
}
.right-content .model-swiper-container .model-swiper-slide img{
  width: 100%;
  height: 100%;
}
.right-content .model-swiper-container .model-swiper-slide video{
  max-height: 100%;
}
.right-content .model-swiper-prev{
  position: absolute;
  width: 50px;
  height: 50px;
  left: 20px;
  cursor: pointer;
  background-color: #FBAE17;
  border-radius: 100%;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.right-content .model-swiper-prev:after{
  content: none;
}
.right-content .model-swiper-next{
  position: absolute;
  width: 50px;
  height: 50px;
  right: 20px;
  cursor: pointer;
  background-color: #FBAE17;
  border-radius: 100%;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.right-content .model-swiper-next:after{
  content: none;
}
.right-content .right-content-video{
  width: 100%;
  height: 100%;
  border: 10px solid #FBAE17;
  border-radius: 10px;
}
.watermark{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 300;
  transition: all .1s;
}
.preview-swiper{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0,0,0,.6);
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}
.preview-icon{
  position: absolute;
  top: -40px;
  width: 40px;
  height: 40px;
  background-color: #FBAE17;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 20px;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
}
.preview-swiper-container{
  height: 200px;
  width: 100%;
}
.preview-swiper-slide{
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview-swiper-slide img{
  height: auto;
}
.preview-swiper-slide video{
  height: auto;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .1s
}
.fade-enter, .fade-leave-active {
  opacity: 0
}
swiper2-button-prev:after{
  content: none;
}
.swiper2-button-prev{
  width: 60px;
  height: 60px;
  left: 0;
  cursor: pointer;
  top: 70px;
  margin-top: 0;
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  border-radius: 0 100px 100px 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 30px;
}
.swiper2-button-next:after{
  content: none;
}
.swiper2-button-next{
  width: 60px;
  height: 60px;
  right: 0;
  cursor: pointer;
  top: 70px;
  margin-top: 0;
  position: absolute;
  background-color: rgba(0,0,0,0.6);
  border-radius: 100px 0 0 100px;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
  font-size: 30px;
}
</style>
